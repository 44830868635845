import Button from 'components/inputs/ButtonM';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import classNames from 'classnames';

import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/feedbacks/Dialog';

export default function Pallets() {
  const xdock = useGState((s) => s.branch);
  const { data: pallets = [], refetch } = useQuery(xdock?.id && ['pallets', xdock.id], () =>
    fetch(`/falcon/xdock/assets/panel/pallets?xdockId=${xdock.id}`)
  );
  const [palletDialogOpen, setPalletDialogOpen] = useState(false);
  const [palletBarcode, setPalletBarcode] = useState('');
  const [palletCode, setPalletCode] = useState('');

  const [error, setError] = useState('');

  const [addPallet] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: '/falcon/xdock/assets/panel/addPallet',
        data: {
          xdockId: xdock.id,
          barcode: palletBarcode,
          code: palletCode,
        },
      }),
    {
      onSuccess: () => {
        setPalletDialogOpen(false);
        refetch();
      },
    }
  );

  const close = () => {
    setPalletDialogOpen(false);
    setError('');
  };

  const disabledClick = () => {
    setError(`${palletBarcode ? '"Code"' : '"Barcode"'} can not be empty!`);
  };

  return (
    <div class="p-6">
      <div class="flex justify-between items-center">
        <Text class="text-3xl">Pallets</Text>
        <Button variant="contained" onClick={() => setPalletDialogOpen(true)}>
          Add New
        </Button>
      </div>
      <div class="grid md:grid-cols-3  gap-4 pt-6">
        {pallets.map(
          (details) => details.active && <PalletCard pallet={details} key={details.id} />
        )}
      </div>
      <Dialog open={palletDialogOpen} onClose={close} classes={{ paper: 'p-4' }}>
        <DialogTitle>
          <Text class="text-2xl font-bold">Enter details new Pallet</Text>
        </DialogTitle>
        <DialogContent class="p-6">
          <div class="flex-col text-xl text-center">
            <input
              class="flex border border-1 rounded-lg px-4 py-2"
              placeholder="Barcode"
              value={palletBarcode}
              onChange={(e) => {
                setPalletBarcode(e.target.value);
                setError('');
              }}
            />
            <input
              class="flex border border-1 rounded-lg mt-5 px-4 py-2"
              placeholder="Code"
              value={palletCode}
              onChange={(e) => {
                setPalletCode(e.target.value);
                setError('');
              }}
            />
            <Text class="text-base mt-4 text-warn">{error}</Text>
          </div>
        </DialogContent>
        <DialogActions class="justify-center flex p-3">
          <Button onClick={palletBarcode && palletCode ? addPallet : disabledClick}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function PalletCard({ pallet: { barcode, status } }) {
  return (
    <div class="bg-white rounded-lg px-5 py-4 shadow flex justify-between items-center">
      <div class="flex-col">
        <Text class="font-bold break-all">{barcode}</Text>
        <Text
          class={classNames('text-tiny', {
            'text-success': status == 'EMPTY',
            'text-warn': status == 'FULL',
          })}
        >
          {status}
        </Text>
      </div>
    </div>
  );
}

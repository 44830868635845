import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Dialog = MuiDialog;
const DialogTitle = MuiDialogTitle;
const DialogContent = MuiDialogContent;
const DialogContentText = MuiDialogContentText;
const DialogActions = MuiDialogActions;

export { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions };

import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import Pallets from 'xdock/Pallets';

export default function PalletsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background h-full">
          <Pallets />
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
